.App {
  text-align: center;
  min-height: 100vh;
}

.App-body {
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
