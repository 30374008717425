.title {
    margin-bottom: 10px;
}

.hint-text {
    font-size: 1rem;
    margin-bottom: 10px;
}

.topics-list {
    list-style-type: none;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    margin-left: 10%;
    margin-right: 10%;
}

.topics-list .topic-item {
    text-align: left !important;
    padding-left: 30%;
}

@media only screen and (min-width: 840px) {
    .topics-list .topic-item {
        padding-left: 30%;
    }
}

@media only screen and (min-width: 601px) and (max-width: 839px) {
    .topics-list .topic-item {
        padding-left: 25%;
    }
}

@media only screen and (max-width: 601px) {
    .topics-list .topic-item {
        padding-left: 10%;
    }
}


.topics-list .topic-item label {
    line-height: 1.6em;
}

.topics-list input {
    margin-right: 10px;
    width: 1.6em;
    height: 1.6em;
}

.topics-list .topic-item label .count-text {
    margin-left: 10px;
    font-size: 1rem;
}

.link-back-to-app {
    color: rgb(209 213 219);
    border: 2px solid #FF5800;
    margin-bottom: 20px;
}