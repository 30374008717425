

@media only screen and (max-width: 750px) {
    .img-dsk {
        display: none;
    }
    .img-mob {
        display: block;
        max-width: 100%;
    }
}

@media only screen and (min-width: 751px) {
    .img-dsk {
        display: block;
        /* max-width: 600px; */
        width: 800px;
    }
    .img-mob {
        display: none;
    }
}
