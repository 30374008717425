.colored {
    color: #FF5800;
}

#links-list {
    list-style-type: circle;
}

#links-list > li {
    padding-bottom: 3px;
}
