
.webcam {
    border: 3px solid silver;
}

@media only screen and (min-width: 601px) {
    .webcam {
        min-height: 375px;
    }
}

.camera-warning {
    font-size: 1rem;
}