.navigation-list {
    list-style-type: none;
}

a {
    color: rgb(209 213 219);
}

a.active {
    border: 2px solid #FF5800;
}

a:hover {
    border-bottom: 2px solid #FF5800;
}
